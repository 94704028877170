import Vue from 'vue';
import axios from 'axios';

// axios.interceptors.request.use((config) => {
//     config.url += `?_=${Math.random()}`;
//     return config;
// });

axios.interceptors.request.use((config) => {
    if (config.method === 'get' || config.method === 'GET') {
        config.params = {
            ...config.params,
            _: Math.random(),
        };
    }
    return config;
});

// const axiosInstance = axios.create({
//     baseURL: 'https://appcoralleisure.ie/backend/public/api/', // Replace with your API base URL
//     headers: {
//         'Content-Type': 'application/json',
//         'Cache-Control': 'no-cache',
//         // Add your authorization header if you need it
//         // 'Authorization': 'Bearer ' + localStorage.getItem('token'),
//     },
// });

// axiosInstance.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         if (error.response && error.response.status === 401) {
//             // Redirect to the login page when a 401 response is received
//             window.location.href = '/staff/login'; // Replace '/login' with your actual login page URL
//         }
//         return Promise.reject(error);
//     }
// );

// Vue.prototype.$http = axiosInstance;

//axios.defaults.headers.common['Authorization']="Bearer "+ localStorage.getItem('token');
/*if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
axios.defaults.baseURL='http://localhost/backend/public/api/';
else*/
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';

//axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';
// axios.defaults.baseURL = 'https://appcoralleisure.ie/backend/public/api/';
axios.defaults.baseURL = 'https://app.centumx.ie/backend/public/api/';

Vue.prototype.$http = axios;
