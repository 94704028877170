<template>
    <div class="container">
        <!--  BEGIN NAVBAR  
    <Header></Header>
      END NAVBAR  -->

        <!--  BEGIN MAIN CONTAINER  -->
        <div class="row">
            <!--  BEGIN SIDEBAR  
      <Sidebar></Sidebar>
       END SIDEBAR  -->

            <!--  BEGIN CONTENT AREA  -->
            <div id="content" class="col-md-6 p-0 m-auto">
                <router-view>
                    <!-- BEGIN LOADER -->
                    <div id="load_screen">
                        <div class="loader">
                            <div class="loader-content">
                                <div class="spinner-grow align-self-center"></div>
                            </div>
                        </div>
                    </div>
                    <!--  END LOADER -->
                </router-view>
                <Footer></Footer>
            </div>
            <!--  END CONTENT AREA  -->

            <!-- BEGIN APP SETTING LAUNCHER -->

            <!-- END APP SETTING LAUNCHER -->
        </div>
    </div>
</template>
<script>
    import Footer from '@/components/layout/cfooter.vue';

    import axios from 'axios';

    export default {
        components: {
            Footer,
        },
        data() {
            return {
                baseurl: this.$appSetting.hostname,
            };
        },
        beforeCreate() {
            if (!this.$store.state.center) {
                axios
                    .get('getCenterDetails/' + window.location.host)
                    .then((res) => {
                        if (res.data.error) {
                            console.log(res);
                        } else {
                            this.$store.dispatch('center', res.data.data);
                            // const favicon = document.getElementById("favicon");
                            // favicon.href = this.baseurl + res.data.data.logo;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            if (!this.$store.state.user) {
                if (localStorage.getItem('token')) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
                    axios
                        .get('getCustomerProfile')
                        .then((res) => {
                            if (res.data.error) {
                                console.log('Error');
                                //this.$swal('Login Failed', res.data.msg, 'error');
                                this.$router.push('/customer/login').catch(() => {
                                    this.$router.push('/customer/login');
                                });
                            } else {
                                this.$store.dispatch('user', res.data.data);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            this.$router.push('/customer/login').catch();
                        });
                } else {
                    // this.$swal('Login Required', 'You need to login', 'error');
                    this.$router.push('/customer/login').catch(() => {
                        this.$router.push('/customer/login');
                    });
                }
            }
        },
        mounted() {},
    };
</script>

<style scoped></style>
